import { Link } from "react-router-dom"
import PaxDetailCard from "./PaxDetailCard"
import { useState } from "react"
export default function DetailCard({ data, commonData }) {
    const [show, setShow] = useState(false)
    const travelType = {
        1: 'One Way',
        2: 'Round Way'
    }
    const paxType = {
        1: 'Adult',
        2: 'Child',
        3: 'Infant'
    }
    const travelCssName = {
        1: 'one',
        2: 'round'
    }
    const style = {
        container: {
            width: '736px',
            height: 'fit-content',
            boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)',
            borderRadius: '1rem',
            padding: '0 1.5rem  1.5rem 1.5rem'
        }
    }
    return (
        <div id={'pax' + data.seqNo} style={style.container}>
            <div className="d-f f-spb">
                <div className="d-f f-spb f-col">
                    <span className="bg-primary white plr-1 fs-14 b-500" style={{ width: 'fit-content', borderRadius: '0 0 4px 4px' }}>{commonData.voyageId}</span>
                    {/* <p><span>Ticket No: </span><span className="b-700">{commonData.bookingId && commonData.bookingId.map(item => "-" + item)}-{data.seqNo}</span></p> */}
                    <Link className="primary" onClick={() => setShow(!show)}>{show ? "Close detail" : "View details"}</Link>
                </div>
                <div className="d-f f-col f-spb f-a-c">
                    <p className="b-600 mt-1-5">{data.otherName}&nbsp;{data.sureName}</p>
                    <div className="d-f" style={{ columnGap: '3rem' }}>
                        <div className="d-f f-col f-a-c">
                            <span className="fs-14px b-500">{commonData.dateOfTravel}</span>
                            <span className="fs-1-5 b-500">{commonData.voyageFrom}-{commonData.voyageTo}</span>
                            <span className="fs-14px b-500">{commonData.voyageFromTime} - {commonData.voyageToTime}</span>
                        </div>
                        {commonData.type == 2 && <div className="d-f f-col f-a-c">
                            <span className="fs-14px b-500">{commonData.dateOfTravelReturn}</span>
                            <span className="fs-1-5 b-500">{commonData.voyageFromReturn}-{commonData.voyageToReturn}</span>
                            <span className="fs-14px b-500">{commonData.voyageFromTimeReturn} - {commonData.voyageToTimeReturn}</span>
                        </div>
                        }
                    </div>
                    <p className="mt-1">Pax Type: <span className="b-700">{paxType[data.passengerType]}</span></p>
                    <p><span>Ticket No: </span><span className="b-700">{commonData.bookingId && commonData.bookingId.map(item => "-" + item)}-{data.seqNo}</span></p>
                </div>
                <div className="d-f f-spb f-col">
                    <span className="fs-14px b-500 round plr-1" style={{ width: 'fit-content', borderRadius: '0 0 4px 4px', alignItems: "end" }}>{travelType[commonData.type]}</span>
                    <span className="br-r-8 economy" style={{ padding: '4px 8px' }}>{commonData.className}</span>
                    {/* <p>Seat No: <span className="b-700">N/A</span></p> */}
                </div>

            </div>
            {show && <PaxDetailCard data={data} />}
        </div>
    )
}