import { useState } from "react"
import { Link } from "react-router-dom"
export default function Profile({ name }) {
    const [drop, setDrop] = useState(false);
    return (
        <div className="p-r pointer" style={{ width: '192px' }}>
            <div onClick={() => setDrop(!drop)} className="d-f c-gap-1 p-1 br-r-8 f-spb f-a-c" style={{ borderRadius: drop ? '8px 8px 0 0' : '8px', background: 'var(--bgGray)', height: '56px' }}>

                <svg style={{ flexShrink: 0 }} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5 0C9.18593 0.00370554 6.00866 1.32186 3.66526 3.66526C1.32186 6.00866 0.00370554 9.18593 0 12.5C0.0055527 15.8135 1.3243 18.9897 3.6673 21.3327C6.0103 23.6757 9.1865 24.9944 12.5 25C15.8152 25 18.9946 23.683 21.3388 21.3388C23.683 18.9946 25 15.8152 25 12.5C25 9.18479 23.683 6.00537 21.3388 3.66117C18.9946 1.31696 15.8152 0 12.5 0ZM20.103 19.713C19.401 18.297 18.3172 17.1054 16.974 16.2726C15.6307 15.4397 14.0815 14.9989 12.501 14.9997C10.9206 15.0005 9.37177 15.443 8.02941 16.2773C6.68705 17.1115 5.60453 18.3043 4.904 19.721C3.03491 17.7825 1.99344 15.1928 2 12.5C2 9.71523 3.10625 7.04451 5.07538 5.07538C7.04451 3.10625 9.71523 2 12.5 2C15.2848 2 17.9555 3.10625 19.9246 5.07538C21.8938 7.04451 23 9.71523 23 12.5C23.0063 15.189 21.9675 17.7753 20.103 19.713ZM12.5 5C11.61 5 10.74 5.26392 9.99993 5.75839C9.25991 6.25285 8.68314 6.95566 8.34254 7.77792C8.00195 8.60019 7.91283 9.50499 8.08647 10.3779C8.2601 11.2508 8.68868 12.0526 9.31802 12.682C9.94736 13.3113 10.7492 13.7399 11.6221 13.9135C12.495 14.0872 13.3998 13.9981 14.2221 13.6575C15.0443 13.3169 15.7471 12.7401 16.2416 12.0001C16.7361 11.26 17 10.39 17 9.5C17 8.90905 16.8836 8.32389 16.6575 7.77792C16.4313 7.23196 16.0998 6.73588 15.682 6.31802C15.2641 5.90016 14.768 5.56869 14.2221 5.34254C13.6761 5.1164 13.0909 5 12.5 5Z" fill="var(--primaryColor)" />
                </svg>

                <p style={{ height: "24px", overflow: "hidden", whiteSpace: 'nowrap' }}>{name}</p>

                <svg style={{ flexShrink: 0 }} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 1L5 5L1 1" stroke="#2500BA" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
            {drop && <div style={{ width: '100%', height: "100%", position: 'fixed', top: 0, left: 0 }} onClick={() => setDrop(false)}></div>}

            {drop && <div className="d-f f-col txt-a-c p-a bg-white r-gap-1 ptb-1" style={{ borderRadius: '0 0 8px 8px', width: '100%', background: 'var(--bgGray)' }}>
                <Link to="/dashboard" onClick={() => setDrop(false)} className="txt-dec-n br-r-8 bg-primary d-f f-j-c f-a-c c-gap-1 m-a" style={{ width: "171px", height: '48px' }}>
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.85 11H14.7C12.96 11 11.55 12.343 11.55 14V17C11.55 18.657 12.96 20 14.7 20H17.85C19.59 20 21 18.657 21 17V14C21 12.343 19.59 11 17.85 11ZM6.3 11H3.15C1.41 11 0 12.343 0 14V17C0 18.657 1.41 20 3.15 20H6.3C8.04 20 9.45 18.657 9.45 17V14C9.45 12.343 8.04 11 6.3 11ZM17.85 0H14.7C12.96 0 11.55 1.343 11.55 3V6C11.55 7.657 12.96 9 14.7 9H17.85C19.59 9 21 7.657 21 6V3C21 1.343 19.59 0 17.85 0ZM9.45 3V6C9.45 7.657 8.04 9 6.3 9H3.15C1.41 9 0 7.657 0 6V3C0 1.343 1.41 0 3.15 0H6.3C8.04 0 9.45 1.343 9.45 3Z" fill="white" />
                    </svg>
                    <span className="white">Dashboard</span>
                </Link>
                <span className="txt-dec-u primary" onClick={() => {
                    localStorage.clear();
                    window.location.assign('/');
                }}>Logout</span>
                <Link to="/account" onClick={() => setDrop(false)} className="primary">Account</Link>
            </div>}

        </div>
    )
}