export default function RefundPolicy() {
    return (
        <div className="br-r-1 bg-white m-a h-100" style={{ overflow: 'auto', maxWidth: "896px", padding: "2rem 1.5rem", boxShadow: ' 0px 0px 4px 0px rgba(0, 0, 0, 0.25)' }}>
            <h1 className="fs-20px b-500">IndSri Ferry  - Connecting Cultures, Bridging Palk Strait</h1>
            <ul className="">
                <br></br>
                <li style={{listStyleType:"none" , fontWeight:"bold" }}>Refund Policy</li>
                <li className="mt-1-5">
                    Passengers who booked confirmed tickets will get full refund if they cancelled 72 hours before the scheduled departure.
                </li>
                <li className="mt-1-5">
                    Tickets can celled within 72 hours basic fares will NOT be refunded but taxes and levied charged will be refunded in full.
                </li>
                <li className="mt-1-5">
                    Date change before 72 hours is allowed subject to availability and at a cost of USD 12.50 + Applicable taxes.
                </li>
                <li className="mt-1-5">
                    Date change before 72 hours is allowed subject to availability and at a cost of USD 25 + Applicable taxes.
                </li>
                </ul>
                <ul>
                <br></br>

                <hr></hr>

                    <br></br>
                <li style={{listStyleType:"none" , fontWeight:"bold" }}>Baggage Policy</li>

               <li className="mt-1-5">

               

               

               Baggage Allowance:
The combined weight of check-In luggage and hand luggage should not exceed 23 kg per person.
</li><li className="mt-1-5">

Check-In Baggage:
Total combined dimensions (length + breadth + height) of each piece should not exceed 157 cm/62 in and weight should not exceed 18 kg
</li><li className="mt-1-5">

Hand Baggage:
the size should be: length - 55 cm, width - 35 cm, height - 25 cm, and the weight should not exceed 5 kg.
                </li>

             
            </ul>
<ul>
<br></br>

<hr></hr>

    <br></br>
<li style={{listStyleType:"none" , fontWeight:"bold" }}>Visa Requirements
</li>

                <li style={{listStyleType:"none" , fontWeight:500}} className="mt-1-5 ">
For Indian passport holders to enter Sri Lanka requires :</li> <li className="mt-1-5">
⁠Passport with 180 days / 6 months validity from the date of Travel</li> <li className="mt-1-5">
⁠A return ticket either by Sea or Air from Sri Lanka or onward ticket for on ward journey from Sri Lanka </li> <li className="mt-1-5" style={{listStyleType:"none" , fontWeight:500}} >
For Sri Lankan passport holders to enter India requires :</li> <li className="mt-1-5">
⁠Require a valid visa issued by an Indian High Commission or Embassy. </li> <li className="mt-1-5">
⁠If it is NEW e-visa they cannot enter the Nagapattinam port for the first time. However if they have  already entered India using the same e-visa they can enter through Nagapattinam </li> <li className="mt-1-5">
⁠Return or onward journey to another country.</li> <li className="mt-1-5" style={{listStyleType:"none" , fontWeight:500}} >
For all other International passport Holders to enter India requires :</li> <li className="mt-1-5">
⁠A valid passport with 6 months / 180 days from the date of entry</li> <li className="mt-1-5">
If the passport issuing country is exempted from visa to enter India then the passenger can enter Inda, else you should have a valid visa to enter India issued by the Indian High Commission or Embassy. </li> <li className="mt-1-5">
⁠First time entry with an e-visa India is not permitted to enter through Nagapattinam. However subsequent entry are allowed. </li> <li className="mt-1-5">
⁠All tourists or visitors requires a valid return or onward journey ticket </li> <li className="mt-1-5" style={{listStyleType:"none" , fontWeight:500}} >

For all other International passport holders to enter Sri Lanka :</li> <li className="mt-1-5">
⁠A valid passport with 6 months / 180 days from the date of entry</li> <li className="mt-1-5">
⁠If the passport issuing country is in the visa on arrival category no need to have a visa to enter Sri Lanka otherwise you should apply get a visa to enter Sri Lanka. </li> <li className="mt-1-5">
⁠All tourists or visitors requires a valid return or onward journey ticket</li> 


                </ul>

            <div className="txt-a-c mt-2">
                {/* <button className="btn pointer" onClick={() => setShowTermsConditions(false)}>Continue</button> */}
            </div>
        </div>
    )
}