import { Routes, Route, Link, useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import Home from "../pages/Home";
import Voyages from "../pages/Voyages";
import PlanAndBook from "../pages/PlanAndBook";
import Support from "../pages/Support";
import TravelInformation from "../pages/TravelInformation";
import Register from "../components/auth/Register";
import Login from "../components/auth/Login";
import Verify from "../components/auth/Verify";
// import Payment from "../components/common/Payment";
import FareSummary from "../components/common/FareSummary";
import VoyageList from "../components/common/VoyageList";
import PassengerDts from "../components/common/PassengerDts";
import Verification from "../components/auth/Verification";
import Forgot from "../components/auth/Forgot";
import ResetPassword from "../components/auth/ResetPassword";
import Dashboard from "../pages/Dashboard";
import Detail from "../pages/Detail";
import SeatSelection from "../components/common/SeatSelection";
import Account from "../components/auth/Account";
import Invoice from "../components/common/Invoice";
import RePlanVoyages from "../components/common/RePlanVoyages";
import Tap from "./Tap";
import ServerErr from "../components/ServerErr";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import AccountDeletion from "../pages/AccountDeletion";
import ChangePassword from "../components/auth/ChangePassword";
import TermsAndConditions from "../pages/TermsConditions";
import EmailTicket from "../components/common/EmailTicket";
import RefundPolicy from "../pages/RefundPolicy";
import { BackBtn } from "../components/btn";
import { useState } from "react";
import { useMediaQuery } from 'react-responsive';
import Test from "../pages/Test";
import AboutUs from "../pages/AboutUs";
import ContactUs from "../pages/ContactUs";
import MenuIcon from "../components/icons/MenuIcon";
import Close from "../components/icons/Close";
import ChangeDate from "../components/changeDate/ChangeDate";
import ChangeDatePage from "../pages/ChangeDatePage";
import ChangeDateVoyageList from "../components/changeDate/ChangeDateVoyageList";
import DateChangeFareSummary from "../components/common/DateChangeFareSummary";
import PaymentFailed from "../components/common/PaymentFailed";
import AlertMsg from "../components/common/AlertMsg";
export default function Layout() {
    const url = useLocation();
    const token = localStorage.getItem('token') ? true : false;
    const [isLogin, setIsLogin] = useState(token)
    const username = localStorage.getItem('username');
    function logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        setIsLogin(false);
    }
    const [isClicked, setIsClicked] = useState(false)
    const isMob = useMediaQuery({ query: '(max-width:1024px)' });
    const [showNews, setShowNews] = useState(true)
    return (
        <div style={{ maxWidth: 'var(--maxWidth)', margin: 'auto' }}>
            {!isMob && <Header username={username} isLogin={isLogin} logout={logout} />}
            {isMob && <div id="backBtnContainer" className="bg-primary d-f f-a-c" style={{
                width: "fit-content",
                position: 'fixed',
                zIndex: 2,
                width: '100%',
                left: '0px',
                top: "0px",
                height: "40px",
                padding: '0 5px',
                justifyContent: !(url.pathname == '/') ? 'space-between' : 'end'
            }}>
                {!(url.pathname == '/') && <BackBtn />}
                {/* <p className="white txt-a-c b-500" style={{ flexGrow: 1 }}></p> */}
                <div className="d-f f-a-c" style={{ marginRight: "5px" }} onClick={() => setIsClicked(!isClicked)}>
                    {isClicked ? <Close /> : <MenuIcon />}
                </div>
            </div>
            }
            {/* {showNews && <AlertMsg setShowNews={setShowNews} />} */}
            {<div style={{ display: isClicked ? 'block' : 'none' }}>< MobileNav setIsClicked={setIsClicked} /></div>}
            <main id="main" className="sm-p-0 ptb-2 plr-1-5" style={{ paddingBottom: "40px", marginTop: !isMob ? '64px' : '30px', marginBottom: isMob ? "70px" : 0, minHeight: 'calc(-64px + 100vh) ' }}>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="home" element={<Home />} />
                    <Route path="account" element={<Account />} />
                    <Route path="invoice" element={<Invoice />} />
                    <Route path="replan-voyages" element={<RePlanVoyages />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="dashboard/detail" element={<Detail />} />
                    <Route path="verification" element={<Verification />} />
                    <Route path="verify" element={<Verify />} />
                    <Route path="forgot" element={<Forgot />} />
                    <Route path="reset-password" element={<ResetPassword />} />
                    <Route path="change-password" element={<ChangePassword />} />
                    <Route path="voyages" element={<Voyages />}>
                        <Route index element={<VoyageList />} />
                        {/* <Route path="return" element={<VoyageList />} /> */}
                        <Route path="fare-summary" element={<FareSummary />} />
                        <Route path="passenger-dts" element={<PassengerDts />} />
                    </Route>
                    <Route path="change-date-page" element={<ChangeDatePage />}>
                        <Route index element={<ChangeDateVoyageList />} />
                        {/* <Route path="return" element={<VoyageList />} /> */}
                        <Route path="fare-summary" element={<DateChangeFareSummary />} />
                        <Route path="passenger-dts" element={<PassengerDts />} />
                    </Route>
                    <Route path="seat-selection" element={<SeatSelection />} />
                    {/* <Route path="passenger-dts" element={<PassengerDts />} /> */}
                    <Route path="register" element={<Register />} />
                    {/* <Route path="payment" element={<Payment />} /> */}
                    <Route path="plan&book" element={<PlanAndBook />} />
                    <Route path="travel-information" element={<TravelInformation />} />
                    <Route path="support" element={<Support />} />
                    <Route path="server-err" element={<ServerErr />} />
                    <Route path="privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="accountdeletion" element={<AccountDeletion />} />
                    <Route path="terms-conditions" element={<TermsAndConditions />} />
                    <Route path="/refund-policy" element={<RefundPolicy />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="ticket/download/:cCode/:dCode/:year/:SNo" element={<EmailTicket />} />
                    <Route path="/test" element={<Test />} />
                    <Route path="/change-date" element={<ChangeDate />} />
                    <Route path="/payment-failed" element={<PaymentFailed />} />
                </Routes>
            </main>
            {(localStorage.getItem('token') && isMob) && <Tap />}
            {
                (!isMob) && <footer className="bg-primary w-100 d-f f-j-c c-gap-1" style={{ position: "fixed", zIndex: 1, bottom: 0, padding: '.5rem', left: 0 }}>
                    <p className="white fs-12px b-500">COPYRIGHT 2024 © INDSRI FERRY SERVICES PRIVATE LIMITED
                        &nbsp; Reg No: U61200TN2020PTC140333
                        &nbsp; GST No: 33AAFCI9579J1ZZ
                    </p>
                    {/* <Link to="privacy-policy" className="white fs-12px b-500">Privacy Policy</Link> */}
                </footer>
            }
        </div >
    )
}

function MobileNav({ setIsClicked }) {
    const navigate = useNavigate();
    const style = {
        container: {
            background: 'white',
            width: "100vw",
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 1
        },
        btn: {
            width: '171px',
            height: '48px',
            border: 'none',
            color: 'white',
            textDecoration: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center"
        }
    }
    const [currentId, setCurrrentId] = useState(0)
    const data = [
        {
            id: 1,
            name: 'Book Now',
            link: "/home"
        },
        {
            id: 2,
            name: 'About Us',
            link: "/about-us"
        },
        {
            id: 3,
            name: 'Privacy Policy',
            link: '/privacy-policy'
        },
        {
            id: 4,
            name: 'Travel Policies',
            link: '/refund-policy'
        },
        {
            id: 5,
            name: 'Contact Us',
            link: '/contact-us'
        },
        ,
    ]
    return (
        <div style={style.container}>
            <div className='d-f f-col h-100'>
                {/* <div style={{ textAlign: 'right', margin: '2rem 2rem 0 0' }}>
                    <span onClick={() => onClick(click => !click)} style={{ display: 'inline-block' }}>
                        <Close />
                    </span>

                </div> */}
                <div className='d-f f-col f-spb a-i-c' style={{ flexGrow: 1 }}>
                    <div className='d-f f-col r-gap-2 txt-a-c f-j-c' style={{ height: '100%' }}>
                        {data.map(item => <Link key={item.id} onClick={(e) => { e.preventDefault(); setIsClicked(false); setCurrrentId(item.id); navigate(item.link) }} style={{ color: item.id == currentId ? 'var(--primaryColor)' : 'black' }} className='nav-link b-500'>{item.name}</Link>)}
                    </div>
                    {/* <div style={{ marginBottom: '3rem' }}>
                        <Link to="login" className='pointer br-r-8 primary-bg login-btn' style={style.btn}>Login</Link>
                        <span className="txt-dec-u primary" onClick={() => {
                            localStorage.clear();
                            window.location.assign('/');
                        }}>Logout</span>
                    </div> */}
                </div>
            </div>
        </div>

    )
}