import { getData, postData } from "./utils";
export async function getPort() {
    const url = process.env.REACT_APP_DOAMIN + '/Voyage/searchDetails';
    const data = await getData(url)
    if (data) return data.data;
    return '';
}

export async function getSearchDetails() {
    const url = process.env.REACT_APP_DOAMIN + '/Voyage/searchDetails';
    const data = await getData(url)
    if (data) return data;
    return '';
}

export async function getVoyages(params) {
    const url = process.env.REACT_APP_DOAMIN + '/Voyage/matchingVoyage';
    const data = await postData(url, params);
    if (data) return data;
    return '';

}